@media (max-width: $screen-sm) {
    // sm-view
    .cart-items { 
        width: 100%;
        margin-left: 0;
        .cart-items-holder { 
            .cart-item { 
                &:last-of-type {
                    .cart-item-holder {
                        margin: 0;
                    }
                }
                .cart-item-holder {
                    flex-wrap: wrap;
                    padding: 6.5% 6% 4.5%;
                    margin: 0 0 33px;
                    .image { 
                        width: 100%;
                        height: 77.5vw;
                        .image-holder {
                            a {
                                border-radius: 10px;
                                overflow: hidden;
                            }
                        }
                    }

                    .item-desc { 
                        width: 100%;
                        padding: 18px 4% 0;
                        .tems-desc-holder { 
                            .top {
                                min-height: auto;
                                margin: 0 0 35px;
                                .name {
                                    width: 100%;
                                    min-height: 78px;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                }

                                .price { 
                                    width: 102%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin: 0 -4% 0 0;
                                    .value { 
                                        width: auto;
                                        text-align: left;
                                    }

                                    .old-value { 
                                        text-align: left;
                                        width: auto;
                                        margin: 0;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 17px;
                                    }
                                }
                            }

                            .bottom { 
                                .action {
                                    right: -11px;
                                    bottom: 21px;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 10px;
                                    line-height: 12px;
                                    a {

                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
