@media (max-width: $screen-lg) {
    // lg-md view
    .data-group-action {
        margin: -12px 0 0;
        .btn {
            
        }
    }

    .data-group { 
        width: 89.2%;
        &.no-underline {
            .data-group-holder { 
                margin: 0 0 9px;
            }
        }
        .data-group-holder { 
            padding: 0 0 12px;
            margin: 0 0 24px;
            .heading { 
                margin: 0 0 35px;
            }

            .data-holder { 
                width: 55.3%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
