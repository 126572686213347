@media (max-width: $screen-sm) {
    // sm-view
    .data-group { 
        width: 100%;
        &.no-underline {
            .data-group-holder { 
                margin: 0 0 20px;
            }
        }
        .data-group-holder { 
            padding: 0 0 12px;
            margin: 0 0 15px;
            .heading { 
                margin: 0 0 11px;
            }

            .data-holder { 
                width: 100%;
                .check-list { 
                    margin: 13px 0 0;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
