.amount { 
    display: inline-block;
    width: 150px;
    height: 49px;
	.amount-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.increase, .decrease { 
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 31px;
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 31px;
            height: 31px;
            text-align: center;
		}

		input { 
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            border: 1px solid #0B132B;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-sizing: border-box;
		}

		.increase { 
            left: auto;
            right: 16px;
		}
	}
}