@media (max-width: $screen-lg) {
    // lg-md view
    .cart-items { 
        width: 102%;
        margin-left: -1%;
        .cart-items-holder { 
            .cart-item { 
                .cart-item-holder {
                    .image { 

                    }

                    .item-desc { 
                        .tems-desc-holder { 
                            .top {
                                .name {
                                    width: 65.5%;
                                }

                                .price { 
                                    width: 21.5%;
                                    float: right;
                                    .value { 
                                        
                                    }

                                    .old-value { 
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
