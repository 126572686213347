.amount {
  display: inline-block;
  width: 150px;
  height: 49px;
}
.amount .amount-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.amount .amount-holder .increase, .amount .amount-holder .decrease {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 31px;
  height: 31px;
  text-align: center;
}
.amount .amount-holder input {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 1px solid #0B132B;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}
.amount .amount-holder .increase {
  left: auto;
  right: 16px;
}

.cart-items {
  display: inline-block;
  width: 100%;
}
.cart-items .cart-items-holder {
  display: inline-block;
  width: 100%;
}
.cart-items .cart-items-holder .cart-item {
  display: inline-block;
  width: 100%;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder {
  display: flex;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  background: #F2F3F5;
  margin: 0 0 10px;
  padding: 3%;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .image {
  width: 180px;
  height: 180px;
  float: left;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .image .image-holder a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc {
  width: calc(100% - 180px);
  height: auto;
  float: left;
  box-sizing: border-box;
  padding-left: 32px;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top {
  display: inline-block;
  width: 100%;
  min-height: 116px;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .name {
  float: left;
  width: 78.5%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .name a {
  color: #000;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .name a:hover {
  color: #9C2E3B;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price {
  float: left;
  width: 21.5%;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price .value {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price .old-value {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #63636C;
  text-decoration: line-through;
  margin: 14px 0 0;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom {
  display: inline-block;
  width: 100%;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom .amount-block {
  float: left;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom .action {
  position: absolute;
  right: 8px;
  bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom .action a {
  color: #B9B9B9;
  text-decoration: underline;
}
.cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom .action a:hover {
  color: #9C2E3B;
  text-decoration-color: transparent;
}

@media (max-width: 1279px) {
  .cart-items {
    width: 102%;
    margin-left: -1%;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .name {
    width: 65.5%;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price {
    width: 21.5%;
    float: right;
  }
}
@media (max-width: 767px) {
  .cart-items {
    width: 100%;
    margin-left: 0;
  }
  .cart-items .cart-items-holder .cart-item:last-of-type .cart-item-holder {
    margin: 0;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder {
    flex-wrap: wrap;
    padding: 6.5% 6% 4.5%;
    margin: 0 0 33px;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .image {
    width: 100%;
    height: 77.5vw;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .image .image-holder a {
    border-radius: 10px;
    overflow: hidden;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc {
    width: 100%;
    padding: 18px 4% 0;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top {
    min-height: auto;
    margin: 0 0 35px;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .name {
    width: 100%;
    min-height: 78px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price {
    width: 102%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -4% 0 0;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price .value {
    width: auto;
    text-align: left;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .top .price .old-value {
    text-align: left;
    width: auto;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-items .cart-items-holder .cart-item .cart-item-holder .item-desc .tems-desc-holder .bottom .action {
    right: -11px;
    bottom: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
}
.cart-block {
  display: inline-block;
  width: 100%;
  margin: 12px 0 68px;
}
.cart-block .form {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder {
  display: inline-block;
  width: calc(100% + 24px);
  margin-left: -12px;
}
.cart-block .cart-block-holder .cart-items-col {
  width: 64.4%;
  float: left;
  box-sizing: border-box;
  padding: 0 12px;
}
.cart-block .cart-block-holder .cart-items-col .cart-items-col-holder {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col {
  width: 35.6%;
  float: left;
  box-sizing: border-box;
  padding: 0 12px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  background: #F2F3F5;
  padding: 29px 9%;
  margin: 0 0 35px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .heading {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 37px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .heading > * {
  margin: 0;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing {
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item:last-of-type {
  margin: 0;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item .name {
  float: left;
  max-width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 0 5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item .value {
  float: right;
  max-width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .heading {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin: 0 0 20px 5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply {
  display: inline-block;
  width: 100%;
  margin: 0 0 0 5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group {
  width: calc(100% + 15px);
  margin-left: -7.5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col {
  padding: 0 7.5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col.col-1 {
  width: 60%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col.col-2 {
  width: 40%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input {
  text-align: center;
  height: 49px;
  border: 1px solid #b5b5b5;
  color: #b5b5b5;
  background: transparent;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input::-webkit-input-placeholder {
  color: #b5b5b5;
}

.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input:-moz-placeholder {
  color: #b5b5b5;
}

.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input::-moz-placeholder {
  color: #b5b5b5;
}

.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input:-ms-input-placeholder {
  color: #b5b5b5;
}

.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .action {
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .action .btn {
  width: 100%;
  height: 49px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .empty-orders {
  display: inline-block;
  width: 100%;
  margin: 11px 0 54px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .empty-orders .text {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 63px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .empty-orders .action {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .empty-orders .action .btn {
  width: auto;
  min-width: 73%;
  max-width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 5px 0 5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .details {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 24px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .details .name {
  float: left;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .details .date {
  float: right;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items {
  max-width: 50%;
  margin: 0;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items .orderes-items-holder {
  width: auto;
  margin: 0 -7.5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .order-total {
  max-width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin: 0 -7px 0 0;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .status {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid #C9C9C9;
  padding: 24px 0 39px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .status .name {
  float: left;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .status .value {
  float: right;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items {
  display: inline-block;
  width: 100%;
  margin: 5px 0 34px 10px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder {
  display: inline-block;
  width: 100%;
  margin: 0 -7.5px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder .item {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0 7.5px 15px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder .item .item-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder .item .item-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2px;
  background: #000;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder .item .item-holder a:hover img {
  opacity: 0.6;
  transition: all 300ms;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items .orderes-items-holder .item .item-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #e2e2e2;
  padding: 23px 11px 21px 10px;
  box-sizing: border-box;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide {
  padding: 23px 0px 21px 2px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide-2 {
  padding: 12px 0px 17px 2px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .total-value {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .total-value .name {
  float: left;
  max-width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .total-value .value {
  float: right;
  max-width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .discount-value {
  display: inline-block;
  width: 100%;
  margin: 16px 0 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .discount-value .name {
  float: left;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total .discount-value .value {
  float: right;
  max-width: 50%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .action {
  display: inline-block;
  width: 100%;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 8px;
}
.cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .note {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 0 0 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 1279px) {
  .cart-block {
    margin: 12px 0 12px;
  }
  .cart-block .cart-block-holder {
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
    padding: 0 3.7%;
  }
  .cart-block .cart-block-holder .cart-items-col {
    width: 100%;
    padding: 0;
    margin: 0 0 33px;
  }
  .cart-block .cart-block-holder .cart-col {
    width: 100%;
    padding: 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals {
    padding: 29px 5.3% 40px;
    margin-bottom: 43px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .heading {
    margin: 0 0 35px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .heading > * {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item {
    margin: 0 0 22px 2px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item .name {
    margin: 0 0 0 13px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses {
    display: inline-block;
    width: 51%;
    margin: 16px 0 0 9px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group {
    width: calc(100% + 19px);
    margin-left: -9.5px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col {
    padding: 0 9.5px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col.col-1 {
    width: 59.5%;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-group .form-col.col-2 {
    width: 40.5%;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total {
    padding: 23px 0 2px 17px;
    margin-left: 2px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide {
    padding: 23px 0px 21px 11px;
    margin-left: 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide-2 {
    width: 90.5%;
    margin-left: 34px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list {
    padding: 1px 0 0 7px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .details {
    margin: 0 0 21px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order {
    align-items: flex-start;
    margin: 0 0 34px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items {
    margin: 0 0 0 4px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .order-total {
    margin: 10px 0 0 0;
    min-width: 130px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .status {
    padding: 28px 0 13px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .action {
    margin: 18px 0 0;
    text-align: center;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .action .btn {
    max-width: 340px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .note {
    padding: 50px 0 0 5px;
  }
}
@media (max-width: 767px) {
  .cart-block {
    margin: 39px 0 -5px;
    overflow: hidden;
  }
  .cart-block .cart-block-holder {
    padding: 0 4.7%;
  }
  .cart-block .cart-block-holder .cart-items-col {
    margin: 0 0 23px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals {
    padding: 29px 5.5% 26px;
    width: 115.935%;
    margin-left: -7.9675%;
    margin-bottom: 9px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals:last-of-type {
    margin-bottom: 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .heading {
    margin: 0 0 32px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total {
    width: 101%;
    padding: 33px 0 2px 0;
    margin-left: 4px;
    margin-bottom: 15px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide {
    width: 100%;
    margin: 0;
    padding: 29px 0px 21px 5px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .total.wide-2 {
    width: 92.5%;
    margin-left: 5%;
    margin-bottom: 0;
    padding: 24px 0px 17px 2px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .ordered-items {
    margin: 5px 0 34px 5px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list {
    padding: 11px 5% 0 6%;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .details {
    margin: 0 0 24px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order {
    align-items: center;
    margin: 0 0 20px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items {
    margin: 0 0 0 -1px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items .orderes-items-holder {
    margin: 0 -6.5px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .ordered-items .orderes-items-holder .item {
    margin: 0 6.5px 13px;
    width: 17vw;
    height: 18.5vw;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .items-in-order .order-total {
    min-width: auto;
    margin: 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .orders-list .orders-list-holder .order-details .status {
    padding: 24px 0 36px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing {
    box-sizing: border-box;
    padding: 0 2.5%;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item {
    margin: 0 0 22px 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .price-listing .price-item .name {
    margin: 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses {
    margin: 15px 0 0;
    width: 100%;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply {
    margin: 0 0 0 1px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input:-moz-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input::-moz-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .bonuses .bonus-apply form .form-field .input input:-ms-input-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder > .action {
    margin: 8px 0 0;
  }
  .cart-block .cart-block-holder .cart-col .cart-col-holder .totals .totals-holder .note {
    padding: 27px 0 0 9px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}
.data-group-action {
  margin: -18px 0 0;
}
.data-group-action .btn {
  border-radius: 8px;
  min-width: 187px;
  text-align: center;
}

.data-group {
  display: inline-block;
  width: 87.5%;
}
.data-group:last-of-type .data-group-holder {
  border: none;
  padding: 0;
  margin: 0;
}
.data-group.no-underline:last-of-type .data-group-holder {
  margin: 0;
}
.data-group.no-underline .data-group-holder {
  border: none;
  padding: 0;
  margin: 0 0 16px;
}
.data-group .data-group-holder {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #CFCFCF;
  padding: 0 0 13px;
  margin: 0 0 24px;
}
.data-group .data-group-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.data-group .data-group-holder .heading > * {
  margin: 0;
}
.data-group .data-group-holder .data-holder {
  display: inline-block;
  width: 55.3%;
}
.data-group .data-group-holder .data-holder .check-list {
  margin: -4px 0 0;
}
.data-group .data-group-holder .data-holder .check-list .form-field {
  margin-bottom: 13px;
}
@media (max-width: 1279px) {
  .data-group-action {
    margin: -12px 0 0;
  }
  .data-group {
    width: 89.2%;
  }
  .data-group.no-underline .data-group-holder {
    margin: 0 0 9px;
  }
  .data-group .data-group-holder {
    padding: 0 0 12px;
    margin: 0 0 24px;
  }
  .data-group .data-group-holder .heading {
    margin: 0 0 35px;
  }
  .data-group .data-group-holder .data-holder {
    width: 55.3%;
  }
}
@media (max-width: 767px) {
  .data-group {
    width: 100%;
  }
  .data-group.no-underline .data-group-holder {
    margin: 0 0 20px;
  }
  .data-group .data-group-holder {
    padding: 0 0 12px;
    margin: 0 0 15px;
  }
  .data-group .data-group-holder .heading {
    margin: 0 0 11px;
  }
  .data-group .data-group-holder .data-holder {
    width: 100%;
  }
  .data-group .data-group-holder .data-holder .check-list {
    margin: 13px 0 0;
  }
}