.cart-items { 
    display: inline-block;
    width: 100%;
	.cart-items-holder { 
        display: inline-block;
        width: 100%;
		.cart-item { 
            display: inline-block;
            width: 100%;
			.cart-item-holder { 
                display: flex;
                width: 100%;
                border-radius: 10px;
                box-sizing: border-box;
                background: $gray-lighter;
                margin: 0 0 10px;
                padding: 3%;
				.image { 
                    width: 180px;
                    height: 180px;
                    float: left;
					.image-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
						a { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                    transition: all 300ms;
                                }
                            }
							img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 300ms;
							}
						}
					}
				}

				.item-desc { 
                    width: calc(100% - 180px);
                    height: auto;
                    float: left;
                    box-sizing: border-box;
                    padding-left: 32px;
					.tems-desc-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        position: relative;
						.top { 
                            display: inline-block;
                            width: 100%;
                            min-height: 116px;
							.name { 
                                float: left;
                                width: 78.5%;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 21px;
								a { 
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
								}
							}

							.price { 
                                float: left;
                                width: 21.5%;
								.value { 
                                    display: inline-block;
                                    width: 100%;
                                    text-align: center;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 22px;
                                    line-height: 26px;
								}

								.old-value { 
                                    display: inline-block;
                                    width: 100%;
                                    text-align: center;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $gray;
                                    text-decoration: line-through;
                                    margin: 14px 0 0;
								}
							}
						}

						.bottom { 
                            display: inline-block;
                            width: 100%;
							.amount-block { 
                                float: left;
							}

							.action { 
                                position: absolute;
                                right: 8px;
                                bottom: 15px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
								a { 
                                    color: #B9B9B9;
                                    text-decoration: underline;
                                    &:hover {
                                        color: $brand-primary;
                                        text-decoration-color: transparent;
                                    }
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/personal/cart_items";
@import "../../media/mobile/includes/personal/cart_items";
