@media (max-width: $screen-sm) {
    // sm-view
    .cart-block {
        margin: 39px 0 -5px;
        overflow: hidden;
        .cart-block-holder { 
            padding: 0 4.7%;
            .cart-items-col { 
                margin: 0 0 23px;
            }

            .cart-col { 
                .cart-col-holder { 
                    .totals { 
                        padding: 29px 5.5% 26px;
                        width: 115.935%;
                        margin-left: -7.9675%;
                        margin-bottom: 9px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                        .totals-holder { 
                            >.heading { 
                                margin: 0 0 32px;
                            }

                            .total { 
                                width: 101%;
                                padding: 33px 0 2px 0;
                                margin-left: 4px;
                                margin-bottom: 15px;
                                &.wide {
                                    width: 100%;
                                    margin: 0;
                                    padding: 29px 0px 21px 5px;
                                }
                                &.wide-2 {
                                    width: 92.5%;
                                    margin-left: 5%;
                                    margin-bottom: 0;
                                    padding: 24px 0px 17px 2px;
                                }
                            }

                            .ordered-items {
                                margin: 5px 0 34px 5px;
                            }

                            .orders-list { 
                                padding: 11px 5% 0 6%;
                                .orders-list-holder { 
                                    .order-details { 
                                        .details {
                                            margin: 0 0 24px;
                                        }

                                        .items-in-order { 
                                            align-items: center;
                                            margin: 0 0 20px;
                                            .ordered-items { 
                                                margin: 0 0 0 -1px;
                                                .orderes-items-holder {
                                                    margin: 0 -6.5px;
                                                    .item {
                                                        margin: 0 6.5px 13px;
                                                        width: 17vw;
                                                        height: 18.5vw;
                                                    }
                                                }
                                            }

                                            .order-total { 
                                                min-width: auto;
                                                margin: 0;
                                            }
                                        }

                                        .status {
                                            padding: 24px 0 36px;
                                        }
                                    }
                                }
                            }

                            .price-listing { 
                                box-sizing: border-box;
                                padding: 0 2.5%;
                                .price-item { 
                                    margin: 0 0 22px 0;
                                    .name { 
                                        margin: 0;
                                    }

                                    .value {

                                    }
                                }
                            }

                            .bonuses {
                                margin: 15px 0 0;
                                width: 100%;
                                .bonus-apply { 
                                    margin: 0 0 0 1px;
                                    form {
                                        .form-field { 

                                            .input { 
    
                                                input { 
                                                    font-style: normal;
                                                    font-weight: 500;
                                                    font-size: 14px;
                                                    line-height: 17px;
                                                    @include placeholder {
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        font-size: 14px;
                                                        line-height: 17px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            >.action {
                                margin: 8px 0 0;
                                .btn {
                                   
                                }
                            }

                            .note {
                                padding: 27px 0 0 9px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
