.data-group-action {
    margin: -18px 0 0;
    .btn {
        border-radius: 8px;
        min-width: 187px;
        text-align: center;
    }
}

.data-group { 
    display: inline-block;
    width: 87.5%;
    &:last-of-type {
        .data-group-holder { 
            border: none;
            padding: 0;
            margin: 0;
        }
    }
    &.no-underline {
        &:last-of-type {
            .data-group-holder { 
                margin: 0;
            }
        }
        .data-group-holder { 
            border: none;
            padding: 0;
            margin: 0 0 16px;
        }
    }
	.data-group-holder { 
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #CFCFCF;
        padding: 0 0 13px;
        margin: 0 0 24px;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            >* {
                margin: 0;
            }
		}

		.data-holder { 
            display: inline-block;
            width: 55.3%;
			.form-field { 

                .input { 

                    .name { 

                    }

                    input { 

                    }
                }
            }

            .check-list { 
                margin: -4px 0 0;
                .form-field { 
                    margin-bottom: 13px;
                    label.checkcontainer { 
            
                        input { 
            
                        }
            
                        .checkmark { 
            
                        }
            
                        .name { 
            
                        }
                    }
                }
            }
		}
	}
}

@import "../../media/tablet/includes/personal/data_group";
@import "../../media/mobile/includes/personal/data_group";
