.cart-block { 
    display: inline-block;
    width: 100%;
    margin: 12px 0 68px;
    .form {
        display: inline-block;
        width: 100%;
    }
	.cart-block-holder { 
        display: inline-block;
        width: calc(100% + 24px);
        margin-left: -12px;
        .cart-items-col { 
            width: 64.4%;
            float: left;
            box-sizing: border-box;
            padding: 0 12px;
            .cart-items-col-holder { 
                display: inline-block;
                width: 100%;
            }
        }

        .cart-col { 
            width: 35.6%;
            float: left;
            box-sizing: border-box;
            padding: 0 12px;
            .cart-col-holder { 
                display: inline-block;
                width: 100%;
                .totals { 
                    display: inline-block;
                    width: 100%;
                    border-radius: 10px;
                    box-sizing: border-box;
                    background: $gray-lighter;
                    padding: 29px 9%;
                    margin: 0 0 35px;
                    .totals-holder { 
                        display: inline-block;
                        width: 100%;
                        >.heading { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            margin: 0 0 37px;
                            >* {
                                margin: 0;
                            }
                        }

                        .price-listing { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 16px;
                            .price-item { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 16px;
                                &:last-of-type {
                                    margin: 0;
                                }
                                .name { 
                                    float: left;
                                    max-width: 50%;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    margin: 0 0 0 5px;
                                }

                                .value {    
                                    float: right;
                                    max-width: 50%;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                }
                            }
                        }

                        .bonuses { 
                            display: inline-block;
                            width: 100%;
                            .heading { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                text-align: left;
                                margin: 0 0 20px 5px;
                            }

                            .bonus-apply { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 0 5px;
                                form { 

                                    .form-group { 
                                        width: calc(100% + 15px);
                                        margin-left: -7.5px;
                                        .form-col { 
                                            padding: 0 7.5px;
                                            &.col-1 {
                                                width: 60%;
                                            }
                                            &.col-2 {
                                                width: 40%;
                                            }

                                           
                                        }
                                    }

                                    .form-field { 

                                        .input { 

                                            input { 
                                                text-align: center;
                                                height: 49px;
                                                border: 1px solid #b5b5b5;
                                                color: #b5b5b5;
                                                background: transparent;
                                                @include placeholder {
                                                    color: #b5b5b5;
                                                }
                                            }
                                        }
                                    }

                                    .action {
                                        width: 100%;
                                        .btn {
                                            width: 100%;
                                            height: 49px;
                                            padding: 0px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 17px;
                                            border-radius: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .empty-orders { 
                            display: inline-block;
                            width: 100%;
                            margin: 11px 0 54px;
                            .text { 
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                margin: 0 0 63px;
                            }
                        
                            .action { 
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                .btn { 
                                    width: auto;
                                    min-width: 73%;
                                    max-width: 100%;
                                }
                            }
                        }

                        .orders-list { 
                            display: inline-block;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 11px 5px 0 5px;
                            .orders-list-holder { 
                                display: inline-block;
                                width: 100%;
                                .order-details { 
                                    display: inline-block;
                                    width: 100%;
                                    .details { 
                                        display: inline-block;
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        margin: 0 0 24px;
                                        .name { 
                                            float: left;
                                            max-width: 50%;
                                        }
                        
                                        .date { 
                                            float: right;
                                            max-width: 50%;
                                        }
                                    }
                        
                                    .items-in-order { 
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin: 0 0 20px;
                                        .ordered-items { 
                                            max-width: 50%;
                                            margin: 0;
                                            .orderes-items-holder { 
                                                width: auto;
                                                margin: 0 -7.5px;
                                                .item { 
                        
                                                    .item-holder { 
                        
                                                        a { 
                        
                                                            img { 
                        
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                        
                                        .order-total { 
                                            max-width: 50%;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 22px;
                                            line-height: 26px;
                                            margin: 0 -7px 0 0;
                                        }
                                    }
                        
                                    .status { 
                                        display: inline-block;
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        border-top: 1px solid #C9C9C9;
                                        padding: 24px 0 39px;
                                        .name { 
                                            float: left;
                                            max-width: 50%;
                                        }
                        
                                        .value { 
                                            float: right;
                                            max-width: 50%;
                                        }
                                    }
                                }
                            }
                        }

                        .ordered-items { 
                            display: inline-block;
                            width: 100%;
                            margin: 5px 0 34px 10px;
                            .orderes-items-holder { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 -7.5px;
                                .item { 
                                    float: left;
                                    width: 70px;
                                    height: 70px;
                                    margin: 0 7.5px 15px;
                                    .item-holder { 
                                        display: inline-block;
                                        width: 100%;
                                        height: 100%;
                                        a { 
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            overflow: hidden;
                                            border-radius: 2px;
                                            background: $gray-base;
                                            &:hover {
                                                img {
                                                    opacity: 0.6;
                                                    transition: all 300ms;
                                                }
                                            }
                                            img { 
                                                display: inline-block;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                transition: all 300ms;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .total { 
                            display: inline-block;
                            width: 100%;
                            border-top: 1px solid #e2e2e2;
                            padding: 23px 11px 21px 10px;
                            box-sizing: border-box;
                            &.wide {
                                padding: 23px 0px 21px 2px;
                            }
                            &.wide-2 {
                                padding: 12px 0px 17px 2px;
                            }
                            .total-value { 
                                display: inline-block;
                                width: 100%;
                                .name { 
                                    float: left;
                                    max-width: 50%;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 33px;
                                }

                                .value { 
                                    float: right;
                                    max-width: 50%;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 22px;
                                    line-height: 26px;
                                }
                            }

                            .discount-value { 
                                display: inline-block;
                                width: 100%;
                                margin: 16px 0 0px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                .name { 
                                    float: left;
                                    max-width: 50%;
                                }
                            
                                .value { 
                                    float: right;
                                    max-width: 50%;
                                }
                            }
                        }

                        >.action { 
                            display: inline-block;
                            width: 100%;
                            .btn { 
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                border-radius: 8px;
                            }
                        }

                        .note {
                            display: inline-block;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 15px 0 0 4px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/personal/cart";
@import "../../media/mobile/includes/personal/cart";
