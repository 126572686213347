@media (max-width: $screen-lg) {
    // lg-md view
    .cart-block { 
        margin: 12px 0 12px;
        .cart-block-holder { 
            width: 100%;
            margin-left: 0;
            box-sizing: border-box;
            padding: 0 3.7%;
            .cart-items-col { 
                width: 100%;
                padding: 0;
                margin: 0 0 33px;
            }

            .cart-col { 
                width: 100%;
                padding: 0;
                .cart-col-holder { 
                    .totals { 
                        padding: 29px 5.3% 40px;
                        margin-bottom: 43px;
                        .totals-holder { 
                            >.heading { 
                                margin: 0 0 35px;
                                >* {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 24px;
                                    line-height: 28px;
                                }
                            }

                            .price-listing { 
                                .price-item { 
                                    margin: 0 0 22px 2px;
                                    .name { 
                                        margin: 0 0 0 13px;
                                    }

                                    .value {

                                    }
                                }
                            }

                            .bonuses {
                                display: inline-block;
                                width: 51%;
                                margin: 16px 0 0 9px;
                                .heading { 

                                }

                                .bonus-apply { 
                                    form {

                                        .form-group { 
                                            width: calc(100% + 19px);
                                            margin-left: -9.5px;
                                            .form-col { 
                                                padding: 0 9.5px;
                                                &.col-1 {
                                                    width: 59.5%;
                                                }
                                                &.col-2 {
                                                    width: 40.5%;
                                                }
    
                                               
                                            }
                                        }

                                    }
                                    
                                }
                            }

                            .total { 
                                padding: 23px 0 2px 17px;
                                margin-left: 2px;
                                &.wide {
                                    padding: 23px 0px 21px 11px;
                                    margin-left: 0;
                                }
                                &.wide-2 {
                                    width: 90.5%;
                                    margin-left: 34px;
                                }
                            }

                            .orders-list { 
                                padding: 1px 0 0 7px;
                                .orders-list-holder { 
                                    .order-details { 
                                        .details {
                                            margin: 0 0 21px;
                                        }

                                        .items-in-order { 
                                            align-items: flex-start;
                                            margin: 0 0 34px;
                                            .ordered-items { 
                                                margin: 0 0 0 4px;
                                            }

                                            .order-total { 
                                                margin: 10px 0 0 0;
                                                min-width: 130px;
                                            }
                                        }

                                        .status {
                                            padding: 28px 0 13px;
                                        }
                                    }
                                }
                            }

                            >.action {
                                margin: 18px 0 0;
                                text-align: center;
                                .btn {
                                    max-width: 340px;
                                }
                            }

                            .note {
                                padding: 50px 0 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
